import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import withStyles from "react-jss";

import Header from "./header";
import Footer from "./footer";
import "./layout.css";

const Layout = ({ classes, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className="layout">
        <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
          <Footer/>
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
