import { Link, StaticQuery, graphql } from "gatsby";
import React from "react";


function Links({ classes }) {
  return (
    <StaticQuery
      query={linksQuery}
      render={data => {
        const { allMarkdownRemark } = data;
        const Links = allMarkdownRemark.edges
          .filter(edge => edge.node.frontmatter.type === "page")
          .map(edge => (
            <Link
              key={edge.node.frontmatter.path}
              to={edge.node.frontmatter.path}
            >
              {edge.node.frontmatter.title}
            </Link>
          ))
          .sort((a, b) => {
            return a.key > b.key ? 0 : -1;
          });
        return <div className="links">{Links}</div>;
      }}
    />
  );
}

export default Links;

export const linksQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            type
            path
            title
          }
        }
      }
    }
  }
`;
